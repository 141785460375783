var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "total-donors" }, [
    _c("div", { staticClass: "total" }, [
      _c("div", { staticClass: "label" }, [_vm._v("Total de doadores")]),
      _c(
        "div",
        { staticClass: "totals" },
        [
          _vm.isLoading
            ? _c("t-placeholder", { attrs: { lines: 1 } })
            : _c("span", { domProps: { textContent: _vm._s(_vm.totalDonors) } })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "wrap-totals" }, [
      _c("div", { staticClass: "total-inactive" }, [
        _c("div", { staticClass: "label" }, [_vm._v("Inativos")]),
        _c(
          "div",
          { staticClass: "totals" },
          [
            _vm.isLoading
              ? _c("t-placeholder", { attrs: { lines: 1 } })
              : _c("span", {
                  domProps: { textContent: _vm._s(_vm.totalInactive) }
                })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "total-active" }, [
        _c("div", { staticClass: "label" }, [_vm._v("Ativos")]),
        _c(
          "div",
          { staticClass: "totals" },
          [
            _vm.isLoading
              ? _c("t-placeholder", { attrs: { lines: 1 } })
              : _c("span", {
                  domProps: { textContent: _vm._s(_vm.totalActive) }
                })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }