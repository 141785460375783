<template>
  <div class="total-donors">
    <div class="total">
      <div class="label">Total de doadores</div>
      <div class="totals">
        <t-placeholder v-if="isLoading" :lines="1" />
        <span v-else v-text="totalDonors" />
      </div>
    </div>
    <div class="wrap-totals">
      <div class="total-inactive">
        <div class="label">Inativos</div>
        <div class="totals">
          <t-placeholder v-if="isLoading" :lines="1" />
          <span v-else v-text="totalInactive" />
        </div>
      </div>
      <div class="total-active">
        <div class="label">Ativos</div>
        <div class="totals">
          <t-placeholder v-if="isLoading" :lines="1" />
          <span v-else v-text="totalActive" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TDonorsTotals',

  props: {

    isLoading: {
      type: Boolean,
      default: false
    },

    totalDonors: {
      type: Number,
      default: 0
    },

    totalActive: {
      type: Number,
      default: 0
    },

    totalInactive: {
      type: Number,
      default: 0
    },
  }
}
</script>

<style lang="scss" scoped>
// mover para TrackUI
@import '@/assets/sass/colors';

.total-donors,
.total-donors .wrap-totals {
  display: flex;
  justify-content: space-between;

  .label {
    color: $black-300;
    font-size: 12px;
    height: 16px;
  }

  .wrap-totals .label,
  .wrap-totals .totals {
    text-align: right;
    margin-left: 30px;
  }

  .totals {
    height: 30px;
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
  }

  .total .totals {
    color: $gray-600;
  }

  .total-inactive .totals {
    color: $black-300;
  }

  .total-active .totals {
    color: $blue-300;
  }

}
</style>

